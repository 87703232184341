@font-face {
  font-family: "RALEWAY";
  src: url("../public/fonts/raleway/Raleway-Bold.ttf");
}

@font-face {
  font-family: "QUICKSAND-LIGHT";
  src: url("../public/fonts/quicksand/Quicksand-Light.ttf");
}

@font-face {
  font-family: "QUICKSAND-REG";
  src: url("../public/fonts/quicksand/Quicksand-Regular.ttf");
}

@font-face {
  font-family: "QUICKSAND-SEMI";
  src: url("../public/fonts/quicksand/Quicksand-SemiBold.ttf");
}

@font-face {
  font-family: "QUICKSAND-BOLD";
  src: url("../public/fonts/quicksand/Quicksand-Bold.ttf");
}
